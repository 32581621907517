// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-codium-js": () => import("./../src/pages/codium.js" /* webpackChunkName: "component---src-pages-codium-js" */),
  "component---src-pages-corporate-gifting-js": () => import("./../src/pages/corporate-gifting.js" /* webpackChunkName: "component---src-pages-corporate-gifting-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laidback-js": () => import("./../src/pages/laidback.js" /* webpackChunkName: "component---src-pages-laidback-js" */),
  "component---src-pages-match-the-memes-js": () => import("./../src/pages/match-the-memes.js" /* webpackChunkName: "component---src-pages-match-the-memes-js" */),
  "component---src-pages-page-2-tsx": () => import("./../src/pages/page-2.tsx" /* webpackChunkName: "component---src-pages-page-2-tsx" */),
  "component---src-pages-portfolio-js": () => import("./../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-react-movie-js": () => import("./../src/pages/react-movie.js" /* webpackChunkName: "component---src-pages-react-movie-js" */),
  "component---src-pages-react-weather-app-js": () => import("./../src/pages/react-weather-app.js" /* webpackChunkName: "component---src-pages-react-weather-app-js" */),
  "component---src-pages-sunnysideagency-js": () => import("./../src/pages/sunnysideagency.js" /* webpackChunkName: "component---src-pages-sunnysideagency-js" */)
}

